import { Skeleton } from '@mui/material';
import React from 'react';

const SkeletonLoader = () => {
    return (
        <div className="w-full px-[3%] overflow-hidden h-screen">
            <div className="flex flex-row gap-10 mt-5 justify-evenly items-center w-full">
                <div className="flex flex-row  gap-5 w-[20%] @sm:w-[10%]">
                    <Skeleton variant="rounded" width={'100%'} height={40} />
                </div>

                <div className="flex flex-row  gap-5 justify-center items-start w-[45%] @sm:hidden">
                    <Skeleton variant="rounded" width={'18%'} height={40} />
                    <Skeleton variant="rounded" width={'18%'} height={40} />
                    <Skeleton variant="rounded" width={'18%'} height={40} />
                    <Skeleton variant="rounded" width={'18%'} height={40} />
                    <Skeleton variant="rounded" width={'18%'} height={40} />
                </div>
                <div className="flex flex-row  justify-end gap-5 w-[35%] @sm:hidden">
                    <Skeleton variant="rounded" width={'80%'} height={40} />
                </div>
                {/* for mobile version first row start*/}
                <div className="flex flex-row @md:hidden @lg:hidden @lgtab:hidden @xl:hidden @2xl:hidden gap-5 w-[35%]">
                    <Skeleton variant="rounded" width={'100%'} height={40} />
                </div>
                <div className=" w-[55%] flex justify-end gap-4 @md:hidden @lg:hidden @lgtab:hidden @xl:hidden @2xl:hidden">
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="circular" width={40} height={40} />
                </div>

                {/* mobile version first row end */}
            </div>

            <div className="flex flex-row gap-5 my-3 justify-between w-full @sm:hidden">
                <div className="flex flex-row my-5 gap-5 w-[70%]">
                    <Skeleton variant="rounded" width={'10%'} height={40} />
                    <Skeleton variant="rounded" width={'10%'} height={40} />
                    <Skeleton variant="rounded" width={'10%'} height={40} />
                    <Skeleton variant="rounded" width={'10%'} height={40} />
                    <Skeleton variant="rounded" width={'10%'} height={40} />
                    <Skeleton variant="rounded" width={'10%'} height={40} />
                </div>

                <div className="flex flex-row gap-5 my-5 justify-end w-[30%]">
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="circular" width={40} height={40} />
                </div>
            </div>
            <Skeleton
                className="mt-5"
                animation="wave"
                variant="rectangular"
                width={'100%'}
                height={500}
            />

            <div className="flex justify-center items-center gap-5 w-full mt-5">
                <div className="w-[33%] ">
                    <Skeleton
                        className="rounded-t-full"
                        variant="rounded"
                        width={'100%'}
                        height={500}
                    />
                </div>
                <div className="w-[33%]">
                    <Skeleton
                        className="rounded-t-full"
                        variant="rounded"
                        width={'100%'}
                        height={500}
                    />
                </div>
                <div className="w-[33%]">
                    <Skeleton
                        className="rounded-t-full"
                        variant="rounded"
                        width={'100%'}
                        height={500}
                    />
                </div>
            </div>
        </div>
    );
};

export default SkeletonLoader;
