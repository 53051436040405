'use client';
import { Provider } from 'react-redux';
import store from './store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import SkeletonLoader from '@/components/skeleton-loading/index';
// import { PersistGate } from "redux-persist/integration/react";

export default function ReduxProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    let persistor = persistStore(store);
    return (
        <Provider store={store}>
            <PersistGate
                loading={
                    <>
                        <SkeletonLoader />
                    </>
                }
                persistor={persistor}
            >
                {children}
            </PersistGate>
        </Provider>
    );
}
